import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { breakPoints } from '../theme/variables';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const StyledImg = styled(Img)`
    @media (max-width: ${breakPoints.first}) {
        width: 75px !important;
        height: 60px !important;
    }
`;

const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 100, height: 70) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => {
        return <StyledImg fixed={data.file.childImageSharp.fixed} loading="lazy" fadeIn={false} />;
    }}
  />
);


export default Logo;
