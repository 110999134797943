export const colors = {
  primary: '#2E486B',
  primaryLighten: '#7093C2',
  accent: '#BC2028',
  accentLighten: '#DC333C',
  accentDark: '#91191F',
  white: '#FFFFFF',
  black: '#222222',
  dark: '#1D1F27',
  error: '#D8000C',
  disabled: '#AAAAAA',
};

export const fonts = {
  sourceSansPro: `'Source Sans Pro', sans-serif`,
  Nunito: `'Nunito', sans-serif`,
};

export const breakPoints = {
  first: '992px',
  mobile: '420px'
};

export const headerHeight = {
  normal: '80px',
  small: '65px',
  smallest: '61px'
};

export const topHeaderHeight = {
  normal: '36px',
};