/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Globalstyle from '../theme/globalStyle';
import { headerHeight, topHeaderHeight, breakPoints } from '../theme/variables';

import Header from './header';
import Footer from './footer';

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  min-height: calc(100vh - ${headerHeight.normal});
  display: flex;
  flex-direction: column;
  @supports not (position: sticky) {
    margin-top: ${headerHeight.normal};
  }

  @media (max-width: ${breakPoints.first}) {
    min-height: calc(100vh - ${headerHeight.small});
    @supports not (position: sticky) {
      margin-top: ${headerHeight.small};
    }
  }

  @media (max-width: ${breakPoints.mobile}) {
    min-height: calc(100vh - ${headerHeight.smallest});
    @supports not (position: sticky) {
      margin-top: ${headerHeight.smallest};
    }
  }

  ${props =>
    props.home &&
    `
    min-height: calc(100vh - ${headerHeight.normal} - ${
      topHeaderHeight.normal
    });
  `}
`;

const Main = styled.main`
  flex: 1;
`;

const Layout = ({ children, home }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <StyledLayout home={home}>
          <Header home={home} />
          <Body home={home}>
            <Main>{children}</Main>
            <Footer />
          </Body>
          <Globalstyle />
        </StyledLayout>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
