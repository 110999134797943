import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { colors, fonts, breakPoints } from '../theme/variables';
import Logo from './logo';

const StyledHeader = styled.header`
  background-color: ${colors.primaryLighten};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3rem;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  position: sticky;

  @media (max-width: ${breakPoints.first}) {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 20px;
    @supports not (position: sticky) {
      postion: fixed;
    }
  }

  ${props =>
    props.home
      ? ``
      : `
    box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 20px;
    @supports not (position: sticky) {
      postion: fixed;
    }
  `}

  ${props =>
    props.sticky &&
    `
    box-shadow: rgba(0, 0, 0, 0.16) 0px 6px 20px;
    @supports not (position: sticky) {
      postion: fixed;
    }
  `}
  
  @media (max-width: ${breakPoints.first}) {
    padding: 1rem 8rem;
  }

  @media (max-width: ${breakPoints.mobile}) {
    padding: 1rem 3rem;
  }
`;

const NavList = styled.nav`
  display: flex;
  margin-left: auto;

  @media (max-width: ${breakPoints.first}) {
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    overflow-x: hidden;
    background-color: #000;
    padding: 4em 1.5em;
    width: 0;
    transform: translateX(100%);

    ${props =>
      props.opened &&
      `
      transform: translateX(0);
      max-width: 500px;
      width: 100%;
    `}

    ${props =>
      props.move === 'in' &&
      `
      transform: translateX(0%);
      max-width: 500px;
      width: 100%;
      transition-timing-function: ease-in-out;
      transition-duration: 260ms;
      transition-property: transform;
      transition-delay: 0s;
    `}

    ${props =>
      props.move === 'out' &&
      `
      transform: translateX(100%);
      transition-timing-function: ease-in-out;
      transition-duration: 260ms;
      transition-property: transform;
      transition-delay: 0s;
    `}
  }
`;

const NavListItem = styled.nav`
  margin: 0 0.75em;

  :last-child {
    margin: 0 0 0 0.75em;
  }

  @media (max-width: ${breakPoints.first}) {
    & + & {
      margin-top: 2em;
    }
  }

  @media (max-width: ${breakPoints.first}) {
    & + & {
      margin-top: 2.75em;
    }
  }
`;

const NavLink = styled(Link)`
  font-family: ${fonts.Nunito};
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: ${colors.white};
  margin: 0 0.5em;
  transition: color 0.15s ease-out;

  &.active {
    color: ${colors.accentDark};
  }

  :hover {
    color: ${colors.accentLighten};
  }

  :last-child {
    margin: 0 0 0 0.5em;
  }

  @media (max-width: ${breakPoints.first}) {
    text-transform: capitalize;
    font-size: 2.6rem;

    margin: 0 2em;

    :last-child {
      margin: 0 0 0 2em;
    }

    &.active path {
      fill: ${colors.accentDark};
    }

    :hover path {
      fill: ${colors.accentLighten};
    }
  }

  @media (max-width: ${breakPoints.mobile}) {
    text-transform: capitalize;
    font-size: 3rem;

    margin: 0 3em;

    :last-child {
      margin: 0 0 0 3em;
    }
  }

  ${props => props.apply && `
    @media (min-width: 993px) {
      background-color: ${colors.accent};
      padding: 0.25em 0.5em;
      transition: background-color 0.2s ease,color 0.2s ease;

      &.active {
        background-color: ${colors.accentDark};
        color: ${colors.white};
      }

      :hover {
        background-color: ${colors.accentLighten};
        color: ${colors.white};
      }
    }
  `}
`;

const Icon = styled.svg`
  display: none;

  @media (max-width: ${breakPoints.first}) {
    display: inline-block;
    height: 20px;
    width: auto;
    margin-right: 0.5em;
    position: absolute;
    left: 60px;
    transform: translate(-50%, 5%);
  }
`;

const IconBackground = styled.path`
  fill: ${colors.white};
  transition: fill 0.15s ease-out;
`;

const VisuallyHide = styled.span`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

const NavButton = styled.button`
  display: none;
  outline: 0;
  @media (max-width: ${breakPoints.first}) {
    display: block;
    position: relative;
    z-index: 10;
  }
`;

const IconBar = styled.div`
  width: 22px;
  height: 3px;
  border-radius: 1px;
  background-color: #fff;
  transform-origin: 21px;
  transition: transform 0.2s;

  & + & {
    margin-top: 4px;
  }

  :nth-child(3) {
    transition: opacity 0.4s;
  }

  :nth-child(2) {
    ${props => (props.rotate ? `transform: rotate(${props.rotate})` : ``)}
  }

  :nth-child(3) {
    ${props => (props.opacity ? `opacity: ${props.opacity}` : ``)}
  }

  :nth-child(4) {
    ${props => (props.rotate ? `transform: rotate(${props.rotate})` : ``)}
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.animationDuration = 260;
    this.state = {
      slideMenu: {
        toggled: false,
        move: null,
        opened: false,
      },
      sticky: false,
    };

    this.toggleAnimationState = this.toggleAnimationState.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const header = ReactDOM.findDOMNode(this);
    window.onscroll = () => {
      if (window.pageYOffset === header.offsetTop) {
        this.setState({ sticky: true });
      } else {
        this.setState({ sticky: false });
      }
    };
  }

  toggleAnimationState = () => {
    const { slideMenu } = this.state;
    if (slideMenu.toggled) {
      this.setState({
        slideMenu: {
          ...slideMenu,
          toggled: false,
          move: 'out',
        },
      });
      setTimeout(() => {
        this.setState(prevState => ({
          slideMenu: {
            ...prevState.slideMenu,
            move: null,
            opened: false,
          },
        }));
      }, this.animationDuration);
    } else {
      this.setState(prevState => ({
        slideMenu: {
          ...prevState.slideMenu,
          toggled: true,
          move: 'in',
        },
      }));
      setTimeout(() => {
        this.setState(prevState => ({
          slideMenu: {
            ...prevState.slideMenu,
            move: null,
            opened: true,
          },
        }));
      }, this.animationDuration);
    }
  };

  handleClick = () => {
    this.toggleAnimationState();
  };

  render = () => {
    const { slideMenu } = this.state;
    const { toggled, move, opened } = slideMenu;
    const { home } = this.props;
    const { sticky } = this.state;
    return (
      <>
        <StyledHeader home={home} sticky={sticky}>
          <Link to="/" aria-label="Logo">
            <Logo />
          </Link>
          <nav>
            <NavList
              opened={opened}
              move={move}
              duration={this.animationDuration}
            >
              <NavListItem>
                <NavLink to="/" activeClassName="active">
                  <Icon
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <IconBackground d="M5.6 14H2.1V7H0L7 0L14 7H11.9V14H8.4V9.8H5.6V14Z" />
                  </Icon>
                  Főoldal
                </NavLink>
              </NavListItem>
              <NavListItem>
                <NavLink to="/about/" activeClassName="active">
                  <Icon
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <IconBackground d="M2.1371 12.0351C0.8183 10.7604 0 8.9761 0 7C0 3.1339 3.1339 0 7 0C8.9761 0 10.7604 0.8183 12.033 2.135L12.0351 2.1371C13.251 3.3943 14 5.11 14 7C14 10.8661 10.8661 14 7 14C5.11 14 3.3943 13.251 2.135 12.033L2.1371 12.0351ZM11.0481 11.0481C12.0624 10.0338 12.6889 8.6331 12.6889 7.0861C12.6889 3.9914 10.1801 1.4833 7.0861 1.4833C5.5391 1.4833 4.1384 2.1105 3.1241 3.1241C2.1098 4.1384 1.4833 5.5391 1.4833 7.0861C1.4833 10.1808 3.9921 12.6889 7.0861 12.6889C8.6331 12.6889 10.0338 12.0617 11.0481 11.0481ZM6.3861 7.7861V6.3861H7.7861V10.5861H6.3861V7.7861ZM6.3861 3.5861H7.7861V4.9861H6.3861V3.5861Z" />
                  </Icon>
                  Iskolánkról
                </NavLink>
              </NavListItem>
              <NavListItem>
                <NavLink to="/prices/" activeClassName="active">
                  <Icon
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <IconBackground d="M7 0C3.13413 0 0 3.13413 0 7C0 10.8659 3.13413 14 7 14C10.8659 14 14 10.8659 14 7C14 3.13413 10.8659 0 7 0ZM7 12.6C3.9074 12.6 1.4 10.0926 1.4 7C1.4 3.9074 3.9074 1.4 7 1.4C10.0926 1.4 12.6 3.9074 12.6 7C12.6 10.0926 10.0926 12.6 7 12.6ZM7.46667 6.53333V4.66667H9.33333V3.73333H7.46667V2.8H6.53333V3.73333H4.66667V7.46667H6.53333V9.33333H4.66667V10.2667H6.53333V11.2H7.46667V10.2667H9.33333V6.53333H7.46667ZM6.53333 6.53333H5.6V4.66667H6.53333V6.53333ZM8.4 9.33333H7.46667V7.46667H8.4V9.33333Z" />
                  </Icon>
                  Árak
                </NavLink>
              </NavListItem>
              <NavListItem>
                <NavLink to="/teachers/" activeClassName="active">
                  <Icon
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0)">
                      <IconBackground d="M10.5 10.5359V9.81449C11.4638 9.27155 12.25 7.91705 12.25 6.56299C12.25 4.38818 12.25 2.62549 9.625 2.62549C7 2.62549 7 4.38818 7 6.56299C7 7.91749 7.78619 9.27155 8.75 9.81449V10.5359C5.782 10.7787 3.5 12.2369 3.5 14.0001H15.75C15.75 12.2369 13.468 10.7783 10.5 10.5359Z" />
                      <IconBackground d="M4.47344 10.8736C5.22944 10.3793 6.16962 10.0034 7.20475 9.77419C6.99912 9.53094 6.81231 9.25969 6.65131 8.96744C6.23569 8.21231 6.01562 7.38063 6.01562 6.5625C6.01562 5.38606 6.01562 4.27525 6.43387 3.36656C6.83987 2.48456 7.5705 1.93813 8.61087 1.73294C8.37944 0.687312 7.76387 0 6.125 0C3.5 0 3.5 1.76269 3.5 3.9375C3.5 5.292 4.28619 6.64606 5.25 7.189V7.91044C2.282 8.15325 0 9.61144 0 11.3746H3.81456C4.01319 11.1983 4.23281 11.0303 4.47344 10.8732V10.8736Z" />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <rect width="15.75" height="14" />
                      </clipPath>
                    </defs>
                  </Icon>
                  Oktatóink
                </NavLink>
              </NavListItem>
              <NavListItem>
                <NavLink to="/documents/" activeClassName="active">
                  <Icon
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <IconBackground d="M1.4985 2.501L1.5 2.5C1.502 1.947 1.9485 1.5 2.4985 1.5C2.502 0.947 2.9495 0.5 3.501 0.5H8.9995L11.9995 4V13.0045C11.9995 13.551 11.5525 14 11.001 14C10.9975 14.553 10.5515 15 10.001 15C9.9975 15.553 9.5515 16 9.001 16H1.4985C0.945 16 0.5 15.5505 0.5 14.9965V3.504C0.5 2.9495 0.947 2.5005 1.4985 2.5005V2.5015V2.501ZM1.5 3C1.224 3 1 3.2275 1 3.4975V15.002C1 15.277 1.2275 15.4995 1.5 15.4995H9C9.276 15.4995 9.5 15.272 9.5 15.002L2.4985 14.9995C1.945 14.9995 1.5 14.55 1.5 13.996V2.9995V3ZM2.5 2C2.224 2 2 2.2275 2 2.4975V14.002C2 14.277 2.2275 14.4995 2.5 14.4995H10C10.276 14.4995 10.5 14.272 10.5 14.002V13.9995H3.4985C2.945 13.9995 2.5 13.55 2.5 12.996V1.9995V2ZM8.5 1H3.498C3.223 1 3 1.2275 3 1.4975V13.002C3 13.277 3.2275 13.4995 3.5 13.4995H11C11.276 13.4995 11.5 13.277 11.5 13.003V4.4995H9.499C8.9475 4.4995 8.5 4.056 8.5 3.4965V0.9995V1ZM9 1.25V3.4955C9 3.774 9.2255 4 9.4985 4H11.35L9 1.25Z" />
                  </Icon>
                  Dokumentumok
                </NavLink>
              </NavListItem>
              <NavListItem>
                <NavLink apply="true" to="/apply/" activeClassName="active">
                  <Icon
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <IconBackground d="M1.76314 5.25526H13.9982L12.6844 1.31381H3.07695L1.76314 5.25526ZM12.6836 9.60661C13.4228 9.60661 13.9974 9.03203 13.9974 8.29279C13.9974 7.55355 13.4228 6.97898 12.6836 6.97898C11.9443 6.97898 11.3697 7.55355 11.3697 8.29279C11.3697 9.03203 11.9443 9.60661 12.6836 9.60661ZM3.07695 9.60661C3.81619 9.60661 4.39077 9.03203 4.39077 8.29279C4.39077 7.55355 3.81619 6.97898 3.07695 6.97898C2.33771 6.97898 1.76314 7.55355 1.76314 8.29279C1.76314 9.03203 2.33771 9.60661 3.07695 9.60661ZM13.9571 0.861862L15.764 6.11712V13.1381C15.764 13.6313 15.3944 14 14.9022 14H13.9991C13.506 14 13.1373 13.6304 13.1373 13.1381V12.2351H2.62675V13.1381C2.62675 13.6313 2.25713 14 1.76489 14H0.861862C0.368744 14 0 13.6304 0 13.1381V6.11712L1.80693 0.861862C1.9716 0.328454 2.50501 0 3.07958 0H12.6871C13.2616 0 13.7959 0.328454 13.9597 0.861862H13.9571Z" />
                  </Icon>
                  Jelentkezés
                </NavLink>
              </NavListItem>
            </NavList>
            <NavButton onClick={this.handleClick} type="button">
              <VisuallyHide>Toggle navigation</VisuallyHide>
              <IconBar rotate={toggled ? '-45deg' : ''} />
              <IconBar opacity={toggled ? '0' : '1'} />
              <IconBar rotate={toggled ? '45deg' : ''} />
            </NavButton>
          </nav>
        </StyledHeader>
      </>
    );
  };
}

export default Header;
