import { createGlobalStyle } from 'styled-components';
import { fonts, breakPoints } from './variables';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600');
  @import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700');

  * {
    margin: 0;
    padding: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%; // 1rem = 10px, 10px/16px = 62.5%
    @media (max-width: ${breakPoints.mobile}) {
      font-size: 50%;
    }
  }

  body {
    font-family: ${fonts.sourceSansPro};
    font-size: 1.6rem;
    font-weight: 400;
    min-height: 100vh;
    line-height: 1;
  }

  ul {
    list-style: none;
    margin: 0;

    li {
      margin: 0;
    }
  }

  a {
    text-decoration: unset;
  }

  button {
    border: initial;
    background: initial;
  }
`;

export default GlobalStyle;
