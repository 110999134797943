import React from 'react';
import styled from 'styled-components';

const MapOuter = styled.a`
  display: block;
  position: relative;
  text-align: right;
  height: 163px;
  width: 280px;
`;

const GmapCanvas = styled.div`
  overflow: hidden;
  background: none !important;
  height: 163px;
  width: 280px;
`;

const GoogleMap = () => (
  <MapOuter
    href="https://www.google.hu/maps/place/Salg%C3%B3tarj%C3%A1n,+Centrum+%C3%81ruh%C3%A1z,+F%C5%91t%C3%A9r+1,+3100/@48.1044344,19.8050687,17z/data=!3m1!4b1!4m5!3m4!1s0x474012b58473a593:0xdc935e92a2e68c78!8m2!3d48.1044344!4d19.8072574"
    target="_blank"
    aria-label="Google maps"
  >
    <GmapCanvas>
      <iframe
        width="280"
        height="163"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Salg%C3%B3tarj%C3%A1n%20F%C5%91t%C3%A9r%201.%201%2FA.%20(SZMT)&t=&z=15&ie=UTF8&iwloc=&output=embed"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        title="google-maps"
      />
    </GmapCanvas>
  </MapOuter>
);

export default GoogleMap;
