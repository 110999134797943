import React from 'react';
import styled from 'styled-components';
import { colors, fonts, breakPoints } from '../theme/variables';
import GoogleMap from './google-map.js';

const StyledFooter = styled.footer`
  background-color: ${colors.dark};
  padding: 7rem;
  position: relative;

  @media (max-width: ${breakPoints.first}) {
    padding: 5rem 1rem 6rem;
  }
`;

const Main = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: ${breakPoints.first}) {
    flex-direction: column;
  }
`;

const Infos = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @media (max-width: ${breakPoints.first}) {
    margin-top: 2em;

    ul + ul {
      margin-left: 3em;
    }
  }

  @media (max-width: ${breakPoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;

    ul + ul {
      margin-left: 0;
      margin-top: 2em;
    }
  }
`;

const FooterInfo = styled.li`
  font-family: ${fonts.Nunito};
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.02em;
  color: ${colors.white};
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 1.5em;
  }
`;

const FooterTitle = styled.strong`
  font-weight: 700;
`;

const Year = styled.span`
  font-family: ${fonts.Nunito};
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: normal;
  letter-spacing: 0.02em;
  color: ${colors.white};

  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
`;

const Footer = () => (
  <StyledFooter>
    <Main>
      <GoogleMap />
      <Infos>
        <ul>
          <FooterInfo>
            <FooterTitle>Cím:</FooterTitle>
            <span>3100 Salgótarján, Fő tér 1. 1/A. (SZMT)</span>
          </FooterInfo>
          <FooterInfo>
            <FooterTitle>Levelezési cím:</FooterTitle>
            <span>3100 Salgótarján, Fazekas Mihály út 6/A.</span>
          </FooterInfo>
        </ul>
        <ul>
          <FooterInfo>
            <FooterTitle>Telefonszém:</FooterTitle>
            <span>+36 20 418 9286</span>
          </FooterInfo>
          <FooterInfo>
            <FooterTitle>E-mail cím:</FooterTitle>
            <span>beresroland78@gmail.com</span>
          </FooterInfo>
        </ul>
      </Infos>
    </Main>
    <Year>© {new Date().getFullYear()}</Year>
  </StyledFooter>
);

export default Footer;
