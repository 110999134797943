import styled from 'styled-components';
import { fonts, colors } from '../theme/variables';

const PrimaryButton = styled.button`
  background-color: ${colors.accent};
  padding: 0.5em 0;
  width: 20rem;
  border-radius: 2px;

  font-family: ${fonts.sourceSansPro};
  font-size: 1.2rem;
  letter-spacing: 0.04em;
  color: ${colors.white};
  text-align: center;

  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;

  :hover {
    background-color: ${colors.accentLighten};
  }

  :active {
    background-color: ${colors.accentDark};
  }

  &:disabled {
    pointer-events: none;
    background-color: ${colors.disabled};
  }

  ${props =>
    props.outline &&
    `
    background-color: initial;
    border: 2px solid ${colors.accent};
    color: ${colors.accent};
    font-size: 1.6rem;
    padding: 0.25em 0;
    margin: 0 auto;

    :hover {
      color: ${colors.white};
      border: 2px solid ${colors.accentLighten};
    }

    :active {
      border: 2px solid ${colors.accentDark};
    }

    &:disabled {
      color: ${colors.disabled};
      border: 2px solid ${colors.disabled};
    }
  `}
`;

export default PrimaryButton;
